import { FEEDBACK_TYPE, NO_OF_SUGGESTED_PROMPTS_SHOW, QUERY_KEY, FEEDBACK_MODULE_TYPE } from '@Constants/commons'
import { FETCH_CHAT_RESPONSE } from '@Constants/query-keys'
import { fetchStreamingResponse, getActiveQuestion, getConversationById, getDownloadMessage, getDownloadConversation } from '@Requests/knowledge.api'
import { useQuery } from '@tanstack/react-query'

export const useFetchConversationById = (conversationId: string, enabled: boolean) => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_CONVERSATION_BY_ID, conversationId],
		queryFn: () => getConversationById(conversationId),
		enabled,
		select: ({ data }) => {
			const suggestedPrompts =
				data.data.prompt_suggestions?.length > NO_OF_SUGGESTED_PROMPTS_SHOW
					? data.data.prompt_suggestions.slice(0, NO_OF_SUGGESTED_PROMPTS_SHOW)
					: data.data.prompt_suggestions ?? []
			const messages: Message[] = []
			for (let i = 0; i < data.data.chat_history.length; i += 2) {
				const question = data.data.chat_history[i]
				const answer = data.data.chat_history[i + 1]
				messages.push({
					sourceDocuments: answer.response_metadata?.source_document ?? [],
					feedbackGiven: answer.feedback_given,
					id: answer.id,
					relevantDocuments: answer.response_metadata?.relevant_documents ?? [],
					question: question.content,
					answer: answer.content,
					files: answer.response_metadata?.files ?? [],
				})
			}
			const metadata: ConversationMetadata = {
				conversationId: data.id,
				suggestedPrompts: suggestedPrompts,
				collectionId: data?.collection_id ?? undefined
			}
			return { messages, metadata }
		},
	})
}

export const useFetchStreamChat = (
	payload: IKnowledgeConversationSessionGenerationRequest,
	isEnabled: boolean,
	signal?: AbortSignal
) => {
	const { data, isLoading, isError, refetch, error } = useQuery({
		queryKey: [FETCH_CHAT_RESPONSE],
		queryFn: () => fetchStreamingResponse(payload, signal),
		enabled: isEnabled,
		refetchOnWindowFocus: false,
	})
	return {
		data,
		isLoading,
		refetch,
		isError,
		error: error as ISessionStreamAPIError,
	}
}

export const useFetchPositiveFeedbackQuestions = () => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_POSITIVE_FEEDBACK_QUESTIONS],
		queryFn: () => getActiveQuestion(FEEDBACK_TYPE.LIKE, FEEDBACK_MODULE_TYPE.KD),
		select: ({ data }) => data,
	})
}

export const useFetchNegativeFeedbackQuestions = () => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_NEGATIVE_FEEDBACK_QUESTIONS],
		queryFn: () => getActiveQuestion(FEEDBACK_TYPE.DISLIKE, FEEDBACK_MODULE_TYPE.KD),
		select: ({ data }) => data,
	})
}

export const useFetchMessage = (conversationId: string, messageId: string) => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_DOWNLOAD_MESSAGE, conversationId, messageId],
		queryFn: () => getDownloadMessage(conversationId, messageId),
		select: ({ data, headers }) => ({ data, headers }),
		enabled: !!conversationId && !!messageId,
	})
}

export const useFetchConversation = (conversationId: string) => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_DOWNLOAD_CONVERSATION, conversationId],
		queryFn: () => getDownloadConversation(conversationId),
		select: ({ data, headers }) => ({ data, headers }),
		enabled: !!conversationId,
	})
}
