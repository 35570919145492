import React from 'react'
import {
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Box,
	Typography,
	Menu,
	MenuItem,
	useTheme,
	Button,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import {
	IconCompass,
	IconDotsVertical,
	IconFolders,
	IconHeart,
	IconLifebuoy,
	IconTrash,
	IconTrashFilled,
	IconX,
	IconDownload,
} from '@tabler/icons-react'
import { useSideBarPage } from '@Hooks/SideBar/useSideBarPage'
import { RIGHT_SIDE_PANEL_WIDTH } from '@Constants/commons'
import { PRIVATE_ROUTES, ROUTE_TITLE } from '@Constants/routes'
import DKMLogo from '@Shared/DKMLogo'

interface MobileDrawerProps {
	isOpen: boolean
	onClose: () => void
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ isOpen, onClose }) => {
	const {
		conversations,
		isError,
		isLoading,
		handleRecentItemClick,
		anchorEl,
		handleMenuClose,
		handleDelete,
		handleActionIcon,
		handleDeleteConfirmationPopup,
		handleFetchConversationDownloadAsPdf,
		popupContent,
	} = useSideBarPage()

	const theme = useTheme()
	const navigate = useNavigate()

	const handleMenuItemClick = (path: string) => {
		if (window.location.pathname !== path) {
			onClose()
		}
	}

	const handleRecentItemClickWithClose = (conversationId: string) => {
		handleRecentItemClick(conversationId, 'conversation')
		onClose()
	}

	const handleActionIconPage = (e: React.MouseEvent<HTMLElement>, item: string) => {
		handleActionIcon(e, item, {
			target: 'knowledge',
			actions: (
				<Button
					data-cy="delete-session-button"
					color="error"
					variant="contained"
					onClick={() => handleDelete(item)}
					endIcon={<IconTrash />}
				>
					{'Delete'}
				</Button>
			),
		})
	}

	return (
		<Drawer
			anchor="left"
			open={isOpen}
			onClose={onClose}
			data-cy="mobile-drawer"
			sx={{
				'.MuiDrawer-paper': {
					transition: 'width 0.3s ease, margin 0.3s ease, opacity 0.3s ease',
				},
				opacity: 0.95,
			}}
		>
			<Box width={RIGHT_SIDE_PANEL_WIDTH} display={'flex'} flexDirection={'column'} flex={1}>
				<Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<DKMLogo />
					</Box>
					<IconButton onClick={onClose} data-cy="mobile-menu-toggle">
						<IconX />
					</IconButton>
				</Box>
				<List>
					<ListItem>
						<Typography variant="h6">Navigation</Typography>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							component={Link}
							to={PRIVATE_ROUTES.knowledge}
							onClick={() => handleMenuItemClick(PRIVATE_ROUTES.knowledge)}
						>
							<ListItemIcon>
								<IconCompass color={theme.palette.primary['90']} />
							</ListItemIcon>
							<ListItemText primary={ROUTE_TITLE[PRIVATE_ROUTES.knowledge]} />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							component={Link}
							to={PRIVATE_ROUTES.workspace}
							onClick={() => handleMenuItemClick(PRIVATE_ROUTES.workspace)}
						>
							<ListItemIcon>
								<IconFolders color={theme.palette.primary['90']} />
							</ListItemIcon>
							<ListItemText primary={ROUTE_TITLE[PRIVATE_ROUTES.workspace]} />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							component={Link}
							to={PRIVATE_ROUTES.favorites}
							onClick={() => handleMenuItemClick(PRIVATE_ROUTES.favorites)}
						>
							<ListItemIcon>
								<IconHeart color={theme.palette.primary['90']} />
							</ListItemIcon>
							<ListItemText primary={ROUTE_TITLE[PRIVATE_ROUTES.favorites]} />
						</ListItemButton>
					</ListItem>
				</List>
				<Box p={2} data-cy="recent-conversations">
					<Typography variant="h6">Recently</Typography>
					{isError && <Typography>Something went wrong!</Typography>}
					{!isError && !isLoading && (
						<List>
							{(conversations ?? []).map((item) => (
								<ListItem
									key={item.id}
									data-cy="recent-conversation-item"
									secondaryAction={
										<IconButton edge="end" className="menu-button" onClick={(event) => handleActionIconPage(event, item.id)}>
											<IconDotsVertical />
										</IconButton>
									}
									disablePadding
								>
									<ListItemButton
										onClick={() => handleRecentItemClickWithClose(item.id)}
										sx={{
											paddingX: 1,
											borderRadius: 1,
											':hover': {
												cursor: 'pointer',
												background: 'rgba(0, 0, 0, 0.04)',
											},
										}}
									>
										<ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
											<IconCompass color={theme.palette.primary['90']} size={16} />
										</ListItemIcon>
										<ListItemText
											primary={item.title}
											primaryTypographyProps={{
												noWrap: true,
												sx: {
													transition: 'width 0.1s ease',
												},
											}}
										/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					)}
				</Box>
				<Box flex={1} />
				<Box>
					<ListItemButton
						onClick={() => {
							navigate(PRIVATE_ROUTES.faqs)
							onClose()
						}}
						sx={{
							color: 'secondary.contrastText',
							px: 2,
							':hover': {
								cursor: 'pointer',
								background: theme.palette.grey[100],
							},
						}}
					>
						<IconLifebuoy size={18} />
						<ListItemText
							sx={{ ml: 1 }}
							primary={
								<Typography variant="subtitle2" fontWeight={500}>
									{'Need help?'}
								</Typography>
							}
						/>
					</ListItemButton>
				</Box>
				<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
					<MenuItem
						onClick={handleDeleteConfirmationPopup}
						sx={{ display: 'flex', justifyContent: 'space-between', width: 150, height: 30 }}
					>
						<Typography variant="body2">Delete</Typography>
						<IconTrashFilled />
					</MenuItem>
					{
						(popupContent?.target === 'knowledge') && <MenuItem
							onClick={handleFetchConversationDownloadAsPdf}
							sx={{ display: 'flex', justifyContent: 'space-between', width: 150, height: 30 }}
						>
							<Typography variant="body2">Download</Typography>
							<IconDownload />
						</MenuItem>
					}
				</Menu>
			</Box>
		</Drawer>
	)
}

export default MobileDrawer
