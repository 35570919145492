import { FILE_UPLOAD_MAX_SIZE, FILE_UPLOAD_MAX_SIZE_UNIT } from './file-attachment'

export enum ERROR_CODE {
	UNAUTHORIZED = 401,
	NOT_ACCEPTABLE = 406,
	UNPROCESSABLE_ENTITY = 422,
	INTERNAL_SERVER_ERROR = 500,
	ABORT_SIGNAL = 20, //Fetch
	NOT_FOUND = 404,
}

export enum STRING_ERROR_CODE {
	TOKEN_LIMIT_EXCEED = 'E_TOKEN_LIMIT_EXCEED',
	DUPLICATE_FILE_FOUND = 'E_DUPLICATE_FILE_FOUND',
	FILE_TYPE_NOT_ALLOWED = 'E_FILE_TYPE_NOT_ALLOWED',
	FILE_SIZE_EXCEED = 'E_FILE_SIZE_EXCEED',
	CONTENT_SAFETY = 'CONTENT_SAFETY',
	GET_EXPERTISE_SKILLS = 'GET_EXPERTISE_SKILLS',
	GENERATE_KEYWORDS = 'GENERATE_KEYWORDS',
	SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK',
	FAVORITE = 'FAVORITE',
	UNFAVORITE = 'UNFAVORITE',
	DELETE_CONVERSATION_SESSION = 'DELETE_CONVERSATION_SESSION',
	DELETE_TASK_SESSION = 'DELETE_TASK_SESSION',
	DOWNLOAD_FILE_ATTACHMENT = 'DOWNLOAD_FILE_ATTACHMENT',
	SUBMIT_CONSENT = 'SUBMIT_CONSENT',
	DELETE_FILE_ATTACHMENT = 'DELETE_FILE_ATTACHMENT',
	MODIFY_KEYWORDS = 'MODIFY_KEYWORDS',
	SAVE_TASK_TOR_TO_SHAREPOINT = 'SAVE_TASK_TOR_TO_SHAREPOINT',
	SUMMARIZE_CONVERSATION_TITLE = 'SUMMARIZE_CONVERSATION_TITLE',
	SUMMARIZE_TASK_TITLE = 'SUMMARIZE_TASK_TITLE',
	GET_CONVERSATION_CHAT_SUGGESTED_PROMPTS = 'GET_CONVERSATION_CHAT_SUGGESTED_PROMPTS',
	GET_MY_WORKSPACE_KD_SESSIONS = 'GET_MY_WORKSPACE_KD_SESSIONS',
	GET_MY_WORKSPACE_TA_SESSIONS = 'GET_MY_WORKSPACE_TA_SESSIONS',
	GET_MY_FAVORITES = 'GET_MY_FAVORITES',
	GET_FAQ_CATEGORIES = 'GET_FAQ_CATEGORIES',
	GET_FAQ_CATEGORY_QUESTIONS_ANSWERS = 'GET_FAQ_CATEGORY_QUESTIONS_ANSWERS',
	DOWNLOAD_MESSAGE_CONTENT = 'DOWNLOAD_MESSAGE_CONTENT',
	DOWNLOAD_CONVERSATION_CONTENT = 'DOWNLOAD_CONVERSATION_CONTENT',
}

export const ERROR_NAME = {
	[ERROR_CODE.ABORT_SIGNAL]: 'AbortError', //Fetch
}

export const ERROR_TITLE = {
	[STRING_ERROR_CODE.CONTENT_SAFETY]: 'Sensitive/Restricted Content',
	[ERROR_CODE.INTERNAL_SERVER_ERROR]: 'Internal Server Error',
	[ERROR_CODE.ABORT_SIGNAL]: 'Content Generation Abort Error',
	[STRING_ERROR_CODE.GET_MY_WORKSPACE_KD_SESSIONS]: 'There was an error while retrieving the conversations.',
	[STRING_ERROR_CODE.GET_MY_WORKSPACE_TA_SESSIONS]: 'There was an error while retrieving the tasks.',
	[STRING_ERROR_CODE.GET_MY_FAVORITES]: 'There was an error while retrieving your favorites.',
	[STRING_ERROR_CODE.GET_FAQ_CATEGORIES]: 'There was an error while retrieving the FAQ categories.',
	[STRING_ERROR_CODE.GET_FAQ_CATEGORY_QUESTIONS_ANSWERS]: 'There was an error while retrieving the FAQ questions.',
}

export const ERROR_MESSAGE_KNOWLEDGE_DISCOVERY = {
	[ERROR_CODE.ABORT_SIGNAL]: 'You have ended the knowledge generation. Feel free to submit a new prompt to continue your conversation!',
	[ERROR_CODE.INTERNAL_SERVER_ERROR]:
		'Looks like we hit a connection issue while curating your Knowledge Discovery response. Please check your internet and give it another try!',
	[STRING_ERROR_CODE.FAVORITE]: 'Hmm... Navigator couldn’t add to your favorites. Please try again.',
	[STRING_ERROR_CODE.UNFAVORITE]: 'Hmm... Navigator couldn’t remove from your favorites. Please try again.',
	[STRING_ERROR_CODE.DELETE_CONVERSATION_SESSION]: 'Hmm... Navigator couldn’t delete the session. Please try again.',
	[STRING_ERROR_CODE.SUMMARIZE_CONVERSATION_TITLE]:
		'Hmm... Navigator couldn’t summarize the session title. Please try again.',
	[STRING_ERROR_CODE.GET_CONVERSATION_CHAT_SUGGESTED_PROMPTS]:
		'Hmm... Navigator couldn’t get the suggested prompts. Please try again.',
}

export const ERROR_MESSAGE_TASK_ASSISTANCE = {
	[ERROR_CODE.ABORT_SIGNAL]: 'You have ended the task generation. Start a new session to continue supercharging your tasks!',
	[ERROR_CODE.INTERNAL_SERVER_ERROR]:
		'Looks like we hit a connection issue while supercharging your task. Please check your internet and give it another try!',
	[STRING_ERROR_CODE.GET_EXPERTISE_SKILLS]:
		'Heads up! Navigator couldn’t fetch the expertise & skills. Please try again.',
	[STRING_ERROR_CODE.GENERATE_KEYWORDS]: 'Heads up! We couldn’t generate your keywords. Please try again.',
	[STRING_ERROR_CODE.DELETE_TASK_SESSION]: 'Hmm... Navigator couldn’t delete the session. Please try again.',
	[STRING_ERROR_CODE.MODIFY_KEYWORDS]: 'Hmm... Navigator couldn’t modify your keywords. Please try again.',
	[STRING_ERROR_CODE.SAVE_TASK_TOR_TO_SHAREPOINT]:
		'Hmm... Navigator couldn’t save your task to SharePoint. Please try again.',
	[STRING_ERROR_CODE.SUMMARIZE_TASK_TITLE]: 'Hmm... Navigator couldn’t auto correct your title. Please try again.',
}

export const ERROR_MESSAGE = {
	[STRING_ERROR_CODE.TOKEN_LIMIT_EXCEED]:
		'Oops, it seems like there’s too much text for the Navigator to handle! Try removing or splitting a file, and we’ll get back on track together!',
	[STRING_ERROR_CODE.DUPLICATE_FILE_FOUND]:
		"Oops! It looks like you've tried to upload a file that is already in the list. Please check your files and try uploading a different one, or proceed with the files you've already added.",
	[STRING_ERROR_CODE.FILE_TYPE_NOT_ALLOWED]:
		'Oops, that file type won’t work. Please upload a PDF, TXT, or DOCX file and give it another go!',
	[STRING_ERROR_CODE.FILE_SIZE_EXCEED]: `Oops, your file exceeds the ${FILE_UPLOAD_MAX_SIZE}${FILE_UPLOAD_MAX_SIZE_UNIT} size limit. Please choose a smaller file to get things moving again!`,
	[ERROR_CODE.INTERNAL_SERVER_ERROR]:
		'Oops! Something went wrong! Please try uploading any other file or continue with the current uploaded files.',
	[STRING_ERROR_CODE.CONTENT_SAFETY]:
		'We strive to maintain a positive and safe space for all users. Your request includes content that may not be appropriate. Kindly revise and submit again.',
	[STRING_ERROR_CODE.SUBMIT_FEEDBACK]: 'Heads up! We couldn’t send your feedback right now. Please try again.',
	[STRING_ERROR_CODE.DOWNLOAD_FILE_ATTACHMENT]: 'Hmm... Navigator couldn’t download the file. Please try again.',
	[STRING_ERROR_CODE.SUBMIT_CONSENT]: 'Hmm... Navigator couldn’t submit your consent. Please try again.',
	[STRING_ERROR_CODE.DELETE_FILE_ATTACHMENT]: 'Hmm... Navigator couldn’t delete the file. Please try again.',
	[STRING_ERROR_CODE.GET_FAQ_CATEGORIES]: 'It could be your internet connection, please come back again later.',
	[STRING_ERROR_CODE.GET_FAQ_CATEGORY_QUESTIONS_ANSWERS]:
		'It could be your internet connection, please come back again later.',
	[STRING_ERROR_CODE.GET_MY_WORKSPACE_KD_SESSIONS]:
		'It could be your internet connection, please come back again later.',
	[STRING_ERROR_CODE.GET_MY_WORKSPACE_TA_SESSIONS]:
		'It could be your internet connection, please come back again later.',
	[STRING_ERROR_CODE.GET_MY_FAVORITES]: 'It could be your internet connection, please come back again later.',
	[STRING_ERROR_CODE.DOWNLOAD_MESSAGE_CONTENT]: 'Hmm... Navigator couldn’t download the message content. Please try again.',
	[STRING_ERROR_CODE.DOWNLOAD_CONVERSATION_CONTENT]: 'Hmm... Navigator couldn’t download the conversation content. Please try again.',
}
