import queryClient from '@Config/queryClientProvider'
import { QUERY_KEY } from '@Constants/commons'
import { ERROR_MESSAGE_KNOWLEDGE_DISCOVERY, ERROR_MESSAGE_TASK_ASSISTANCE, STRING_ERROR_CODE } from '@Constants/errors'
import { FETCH_TASKS_SESSION_LIST } from '@Constants/query-keys'
import { PRIVATE_ROUTES, ROUTE_TITLE } from '@Constants/routes'
import { useDeleteTasksById } from '@Data-Hooks/Mutations/Task/useDeleteTasks'
import { useDeleteConversationSessionById } from '@Data-Hooks/Mutations/useDeleteConversation'
import { useFetchTasksSessionList } from '@Data-Hooks/Queries/Task/useTasksSessionLists'
import { useFetchRecentConversations } from '@Data-Hooks/Queries/useConversations'
import { useDialog } from '@Store/dialog'
import { useSessionCountStore } from '@Store/sessionCount'
import { useSnackbar } from '@Store/snackbar'
import { UseMutateFunction } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFetchConversation } from '../../Data-Hooks/Queries/Knowledge/Conversation/useConversation'
import { ERROR_MESSAGE } from '../../Constants/errors'

type SectionType = 'knowledge' | 'tasks'
type MutationFn<TData = unknown> = UseMutateFunction<TData, Error, { id: string }, unknown>

export const useSideBarPage = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [title, setTitle] = useState('Dashboard')
	const [elevation, setElevation] = useState(0)
	const [isOpenKnowledge, setIsOpenKnowledge] = useState(true)
	const [isOpenTasks, setIsOpenTasks] = useState(true)
	const [selConversationId, setSelConversationId] = useState('')
	const [downloadConversationId, setDownloadConversationId] = useState('')
	const navigate = useNavigate()
	const { openToast } = useSnackbar()
	const { openDialog, closeDialog } = useDialog()
	const { updateKnowledgeDiscoverySessionCount, updateSuperChargeTaskSessionCount } = useSessionCountStore()
	const { data, isLoading, error, isError } = useFetchRecentConversations()
	const {
		data: tasksList,
		isLoading: isTasksListLoading,
		error: tasksListError,
		isError: isTasksListError,
	} = useFetchTasksSessionList()

	const { data: fetchedConversationData, isLoading: isFetchingConversationLoading, isError: isConversationError, } = useFetchConversation(downloadConversationId ?? '')

	// Update the generic store for the session count
	useEffect(() => {
		if (data?.total) updateKnowledgeDiscoverySessionCount(data.total)
		if (tasksList?.total) updateSuperChargeTaskSessionCount(tasksList.total)
	}, [data?.total, tasksList?.total])

	useEffect(() => {
		if(!isFetchingConversationLoading && fetchedConversationData) {
			let url: string
			// Create a link element
			const link = document.createElement('a')
			try {
				const { data, headers } = fetchedConversationData
				// Create a blob from the response data
				const blob = new Blob([data], { type: headers['content-type'] })

				url = window.URL.createObjectURL(blob)

				// Assign url
				link.href = url

				// Get the filename from the content-disposition header
				const contentDisposition = headers['content-disposition']
				const filename = contentDisposition
					? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
					: 'downloadedFile'

				// Set the download attribute and trigger the download
				link.setAttribute('download', filename)
				document.body.appendChild(link)
				link.click()
			} catch (error) {
				openToast('warning', ERROR_MESSAGE[STRING_ERROR_CODE.DOWNLOAD_CONVERSATION_CONTENT])
			} finally {
				// Clean up and remove the link
				document.body.removeChild(link)
				// Revoke the blob URL after a short delay
				setTimeout(() => {
					url && window.URL.revokeObjectURL(url)
				}, 100)
				setDownloadConversationId('')
				handleMenuClose()
			}
		}

	}, [fetchedConversationData, isFetchingConversationLoading])

	useEffect(() => {
		if (isConversationError) {
			openToast('warning', ERROR_MESSAGE[STRING_ERROR_CODE.DOWNLOAD_CONVERSATION_CONTENT])
			setDownloadConversationId('')
		}
	}, [isConversationError])

	const location = useLocation()
	const [popupContent, setPopupContent] = useState<{
		target: 'knowledge' | 'tasks'
		body?: React.ReactNode
		actions?: React.ReactNode
	}>({
		body: null,
		actions: null,
		target: 'knowledge',
	})

	const handleClickCollapse = (section: SectionType) => {
		if (section === 'knowledge') setIsOpenKnowledge(!isOpenKnowledge)
		if (section === 'tasks') setIsOpenTasks(!isOpenTasks)
	}

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const deleteItem = async (
		mutationFn: MutationFn,
		successMessage: string,
		errorMessage: string,
		id: string,
		target: SectionType,
		redirectPath?: string
	) => {
		mutationFn(
			{ id },
			{
				onSuccess: () => {
					openToast('success', successMessage)
					if (location.pathname === PRIVATE_ROUTES.workspace) {
						navigate(0)
						return
					}
					if (redirectPath && location.pathname.includes(id)) {
						navigate(redirectPath)
					}
					// Invalidate only the associated query
					if (target === 'knowledge') {
						queryClient.invalidateQueries({ queryKey: [QUERY_KEY.GET_CHAT_RECENT_CONVERSIONS] })
					} else if (target === 'tasks') {
						queryClient.invalidateQueries({ queryKey: [FETCH_TASKS_SESSION_LIST] })
					}
					closeDialog()
				},
				onError: () => {
					if (target === 'knowledge') {
						openToast('warning', ERROR_MESSAGE_KNOWLEDGE_DISCOVERY[STRING_ERROR_CODE.DELETE_CONVERSATION_SESSION])
					}
					if (target === 'tasks') {
						openToast('warning', ERROR_MESSAGE_TASK_ASSISTANCE[STRING_ERROR_CODE.DELETE_TASK_SESSION])
					}
				},
			}
		)
	}

	const { mutation: deleteTask } = useDeleteTasksById({
		handleOnSuccess: () => {
			// await queryClient.invalidateQueries({ queryKey: [FETCH_TASKS_SESSION_LIST] })
		},
	})

	const { mutation: deleteConversation } = useDeleteConversationSessionById({
		handleOnSuccess: () => {
			// await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.GET_CHAT_RECENT_CONVERSIONS] })
		},
	})

	const handleDelete = async (id: string) => {
		await deleteItem(
			deleteConversation.mutate,
			'Conversation deleted successfully.',
			'Failed to delete conversation.',
			id,
			'knowledge',
			PRIVATE_ROUTES.knowledge
		)
	}

	const handleDeleteTask = async (id: string) => {
		await deleteItem(
			deleteTask.mutate,
			'Task deleted successfully.',
			'Failed to delete task.',
			id,
			'tasks',
			PRIVATE_ROUTES.task
		)
	}

	const handleRecentItemClick = useCallback(
		(itemId: string, itemType: 'conversation' | 'task') => {
			if (itemType === 'conversation') {
				navigate(PRIVATE_ROUTES.conversationId.replace(':id', itemId))
			} else if (itemType === 'task') {
				navigate(PRIVATE_ROUTES.taskSessionId.replace(':id', itemId), { state: { fetch: true } })
			}
		},
		[navigate]
	)

	useEffect(() => {
		if (location.pathname.startsWith(PRIVATE_ROUTES.knowledge)) {
			setTitle(ROUTE_TITLE[PRIVATE_ROUTES.knowledge])
			setIsOpenKnowledge(true)
			setIsOpenTasks(false)
		} else if (location.pathname.startsWith(PRIVATE_ROUTES.workspace)) {
			setTitle(ROUTE_TITLE[PRIVATE_ROUTES.workspace])
		} else if (location.pathname.startsWith(PRIVATE_ROUTES.favorites)) {
			setTitle(ROUTE_TITLE[PRIVATE_ROUTES.favorites])
		} else if (location.pathname.startsWith(PRIVATE_ROUTES.task)) {
			setTitle(ROUTE_TITLE[PRIVATE_ROUTES.task])
			setIsOpenKnowledge(false)
			setIsOpenTasks(true)
		} else if (location.pathname.startsWith(PRIVATE_ROUTES.faqs)) {
			setTitle(ROUTE_TITLE[PRIVATE_ROUTES.faqs])
		} else {
			setTitle('Dashboard')
		}
	}, [location.pathname])

	const handleScroll = () => setElevation(window.scrollY > 0 ? 4 : 0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	const handleActionIcon = (
		event: React.MouseEvent<HTMLElement>,
		selItem: string,
		pC: {
			target: 'knowledge' | 'tasks'
			body?: React.ReactNode
			actions?: React.ReactNode
		}
	) => {
		setPopupContent(pC)
		handleMenuOpen(event)
		setSelConversationId(selItem)
	}

	const handleDeleteConfirmationPopup = () => {
		handleMenuClose()
		const targetName = popupContent.target === 'knowledge' ? 'conversation' : 'task'
		openDialog(
			`Delete this ${targetName}?`,
			`This will delete the content of the ${targetName} and any attached files.`,
			{ body: popupContent.body, actions: popupContent.actions },
			'xs'
		)
	}

	const handleFetchConversationDownloadAsPdf = useCallback(() => {
		setDownloadConversationId(selConversationId)
	},[selConversationId])

	return {
		conversations: data?.items ?? [],
		isLoading,
		error,
		isError,
		deleteRecentConversation: deleteConversation,
		anchorEl,
		title,
		elevation,
		handleMenuClose,
		handleDelete,
		handleDeleteTask,
		handleClickCollapse,
		isOpenKnowledge,
		isOpenTasks,
		tasksList: tasksList?.items || [],
		isTasksListLoading,
		tasksListError,
		isTasksListError,
		handleRecentItemClick,
		handleActionIcon,
		handleDeleteConfirmationPopup,
		handleFetchConversationDownloadAsPdf,
		popupContent,
	}
}
