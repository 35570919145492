import { deleteTasksById } from '@Requests/task.api'
import { DefaultError, useMutation } from '@tanstack/react-query'

export const useDeleteTasksById = ({ handleOnSuccess, handleOnError }: IMutationParameters) => {
	const mutation = useMutation({
		mutationFn: async ({ id }: { id: string }) => await deleteTasksById(id),
		onSuccess: (response, variables) => {
			handleOnSuccess?.(response.data, variables)
		},
		onError: (err: DefaultError) => {
			handleOnError && handleOnError(err)
		},
	})
	return {
		mutation,
	}
}
