import request from '@Config/request'
import { FEEDBACK_TYPE, FEEDBACK_MODULE_TYPE } from '@Constants/commons'
import { fetchAIStreamingResponse } from './stream.api'
export const fetchStreamingResponse = async (
	payload: IKnowledgeConversationSessionGenerationRequest,
	signal?: AbortSignal
) => {
	return fetchAIStreamingResponse('/search/v1/chat', payload, signal)
}

export const fetchConversationSessions = async (size: number, page: number, title = '') => {
	const { data, status, statusText } = await request.post<ISessionsResponse>(`/search/v1/chat/conversations/`, {
		...{ size, page },
		...(title ? { title } : {}),
	})
	return {
		data,
		status,
		statusText,
	}
}

export const getConversationById = async (conversationId: string) => {
	const { data, status, statusText } = await request.get<IConversationHistoryResponse>(
		`/search/v1/chat/conversations/${conversationId}`
	)
	return {
		data,
		status,
		statusText,
	}
}

export const deleteRecentlyChatResponse = async (id: string) => {
	const { data, status, statusText } = await request.delete(`/search/v1/chat/conversations/${id}`)

	return {
		data,
		status,
		statusText,
	}
}

export const getActiveQuestion = async (feedbackType: FEEDBACK_TYPE, itemType: FEEDBACK_MODULE_TYPE) => {
	const { data, status, statusText } = await request.get<IActiveQuestionResponse>(
		`/feedback/v1/feedback/question?feedback_type=${feedbackType}&item_type=${itemType}`
	)
	return {
		data,
		status,
		statusText,
	}
}

export const submitConversationChatFeedback = async (payload: IConversationChatFeedbackPayload) => {
	const { data, status, statusText } = await request.post<string>(`/feedback/v1/feedback/responses/`, payload)
	return {
		data,
		status,
		statusText,
	}
}

export const submitConversationChatSourceFavoriteToggle = async (
	payload: IConversationChatSourceFavoriteTogglePayload
) => {
	const { data, status, statusText } = await request.post<{ msg: string }>(`/search/v1/favourites`, payload)
	return {
		data,
		status,
		statusText,
	}
}

export const knowledgeConversationFileUpload = async (formData: FormData) => {
	const { data, status, statusText } = await request.post<ITaskFileUploadResponse>(
		'/search/v1/chat/token-count',
		formData,
		{
			headers: { 'Content-Type': 'multipart/form-data' },
		}
	)
	return {
		data,
		status,
		statusText,
	}
}

export const knowledgeConversationDeleteUploadedFile = async (collectionId: string, fileName: string) => {
	const { data, status, statusText } = await request.delete(`/search/v1/chat/token-count/${collectionId}/${fileName}`)
	return {
		data,
		status,
		statusText,
	}
}

export const downloadFileKnowledgeDiscovery = async (fileId: string) => {
	const { data, status, statusText, headers } = await request.get(`/search/v1/chat/download-attachment/${fileId}`, {
		responseType: 'blob',
	})
	return {
		data,
		headers,
		status,
		statusText,
	}
}

export const summarizeKnowledgeConversationSessionTitle = async (payload: IConversationTitleSummarizationPayload) => {
	const { data, status, statusText } = await request.post<IConversationTitleSummarizationResponse>(
		`/search/v1/chat/summarize-title`,
		payload
	)
	return {
		data,
		status,
		statusText,
	}
}

export const getKnowledgeConversationChatSuggestedPrompts = async (payload: IGetConversationChatPayload) => {
	const { data, status, statusText } = await request.post<string[]>(`/search/v1/chat/suggested-prompts`, payload)
	return {
		data,
		status,
		statusText,
	}
}

export const submitMarkSourceFavoriteToggle = async (favPayload: IMarkSourceFavoritePayload) => {
	const { data, status, statusText } = await request.post<{ msg: string }>(`/search/v1/favourites`, favPayload)
	return {
		data,
		status,
		statusText,
	}
}

export const submitMarkSourceUnFavoriteToggle = async (id: string) => {
	const { data, status, statusText } = await request.delete(`/search/v1/favourites/${id}`)
	return {
		data,
		status,
		statusText,
	}
}

export const getDownloadMessage = async (conversationId: string, messageId: string) => {
	const { data, status, statusText, headers } = await request.get(
		`/search/v1/download/message/${conversationId}/${messageId}`,{
			responseType: 'blob',
		}
	)
	return {
		data,
		status,
		statusText,
		headers,
	}
}

export const getDownloadConversation = async (conversationId: string) => {
	const { data, status, statusText, headers } = await request.get(
		`/search/v1/download/conversation/${conversationId}`, {
			responseType: 'blob',
		}
	)
	return {
		data,
		status,
		statusText,
		headers,
	}
}
	